import React from 'react'

import { css } from '@emotion/core'

const ConditionalItemWrapper = ({url, sponsored, follow, title, children}) => {

    if ( sponsored === true ) {
        return (
            <a  href={url} 
                rel={`${!follow && 'nofollow'} noreferrer noopener`} 
                title={title}
                target="blank"
                css={sponsored && sponsoredLink}
            >
                {children}
            </a>
        )
    } else  {
        return (
            <div>{children}</div>
        )
    }
}

export default ConditionalItemWrapper

const sponsoredLink = css`
    text-decoration: none;
    color: inherit;
    margin: 0;
    display: block;

    :hover, :active, :visited {
        text-decoration: none;
    }
`