import React, { Fragment } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"

import { MdLink, MdLanguage } from "react-icons/md"

import { css } from '@emotion/core'
import { constants } from '../styles/colors'

import { getLanguages, translateKey, cleanLink } from '../helpers/helpers'
import Layout from "../components/layout"
import SEO from "../components/seo"
import ConditionalItemWrapper from "../components/conditionalItemWrapper"
import ListItem from "../components/listItem"
import ListBtns from "../components/listBtns"

import { i18nStrings } from '../helpers/translations'

import flatten from "lodash/fp/flatten"
import uniq from "lodash/fp/uniq"
import compact from "lodash/fp/compact"
import flow from "lodash/fp/flow"

const List = (props) => {

    const list = props.data.listsYaml
    const items = list.list
    
    console.log("Props", props)

    // Get lang for the page from context (createPage in gatsby-node.js)
    const lang = props.pageContext.lang

    // Get all available languages for list
    const availableLanguages = getLanguages(list)
    // console.log("Available languages are", availableLanguages)

    // Get translation or default language if not translated yet
    const translate = (object, key) => {
        let translation = object[translateKey(lang, key)] || object[translateKey(availableLanguages[0], key)] || object[translateKey('en', key)]
        return translation
    }

    // Define the object with final translation included
    const name = list[translateKey(lang, 'name')]
    const seo_name = items.length > 0 ? "Top " + items.length + " " + name : "Top list"
    const desc = translate(list, 'desc')
    const categories = list.categories || []
    const href = props.location.href ? props.location.href : ''
    // Location exists only at runtime, not build time https://css-tricks.com/how-to-the-get-current-page-url-in-gatsby/
    
    // Take care of preparing alternate language version object
    var hreflang = []

    availableLanguages.forEach( locale => (
        // Declares EN and its /en/localized-url
        hreflang.push({
            [locale]: props.location.origin ? props.location.origin + `/${locale}/${locale === 'en' ? list.slug : list['slug_' + locale]}` : ''
        })
    ))

    // Use lodash wrapper https://stackoverflow.com/questions/526556/how-to-flatten-a-multi-dimensional-array-to-simple-one-in-php
    const curatorsList = items && items.map( item => item.curators )
    const uniqueCurators = items ? flow(flatten,uniq,compact)(curatorsList) : null

    return (
      <Fragment>
        <SEO description={desc} lang={lang} keywords={["List", "Hiplist", ...categories]} title={seo_name} />
        <Helmet>
            {availableLanguages.length > 1 && 
                hreflang.map( locale => {
                    const langCode = Object.keys(locale)[0]
                    if ( langCode !== lang ) {
                        return (
                            <link 
                                key={lang}
                                rel="alternate" 
                                hreflang={langCode}
                                href={locale[langCode]} 
                            />
                        )
                    }
                    return null
                })
            }
        </Helmet>

        <Layout>
            <div css={listContainer} className="container">
                <div css={listMeta}>
                    <div css={listTopBar}>
                        {categories && <div css={itemCategories}>{categories.map( cat => <span key={cat}>#{cat}</span>)}</div>}
                        <ListBtns 
                            url={href} 
                            lang={lang} 
                            title={{
                                fr: `Top ${items.length} ${list.name_fr}`,
                                en: `Top ${items.length} ${list.name}`,
                            }}
                        />
                    </div>

                    <h1>{seo_name}</h1>
                    {availableLanguages.length > 1 && 
                        <div css={languageSwitch}>
                            <Link to={lang === 'en' ? `/fr/${list.slug_fr}` : `/en/${list.slug}` }>
                                <MdLanguage /> {i18nStrings.switchLang[lang]}
                            </Link>
                        </div>
                    }

                    {desc && <p>{desc}</p>}

                    {list.sources && 
                        <div css={listSources}>
                            <h6>Sources</h6>
                            {list.sources.map( (source, index) => {
                                const link = translate(source, 'link')
                                const link_title = translate(source, 'title')

                                return (
                                    <span key={`${link}-${index}`}>
                                        <MdLink />
                                        <a  href={link} 
                                            target="_blank" 
                                            rel="noopener noreferrer nofollow" 
                                            title={link_title}
                                        >
                                            {link_title || cleanLink(link)}
                                        </a>
                                    </span>
                                )
                            })}
                        </div>
                    }

                    {uniqueCurators.length > 0 && 
                        <div css={curatorsIntro}>
                            <h6>{i18nStrings.curator[lang]}{uniqueCurators.length > 1 && 's'}</h6>
                            {uniqueCurators.map( curator => (
                                <div key={curator.id} className="curator__item">
                                    <Img fixed={curator.avatar.childImageSharp.fixed} />
                                    <div className="curator__meta">
                                        <h4>{curator.name}</h4>
                                        <p>{translate(curator, 'bio')}</p>
                                    </div>
                                </div> 
                            ))}
                        </div>
                    }
                </div>

                {items && items.map( (item, index) => {

                    return (
                        <ConditionalItemWrapper 
                            url={item.link} 
                            sponsored={item.sponsored} 
                            follow={item.follow} 
                            title={item.link_title || cleanLink(item.link)}
                            key={item.title + index}
                        >
                            <ListItem 
                                key={item.title + index}
                                index={index}
                                lang={lang}
                                sponsored={item.sponsored}
                                brand={item.brand}
                                date={item.date}
                                date_span={item.date_span}
                                title={translate(item, 'title')}
                                desc={translate(item, 'desc')}
                                link={item.link}
                                link_title={item.link_title}
                                follow={item.follow}
                                tags={translate(item, 'tags')}
                                stats={translate(item, 'stats')}
                                place={translate(item, 'place')}
                                price={item.price}
                                videos={item.videos}
                                image={item.img ? item.img.childImageSharp.fixed : null}
                                img_source={item.img_source}
                                curators={item.curators}
                                curators_notes={translate(item, 'curators_note')}
                            />
                        </ConditionalItemWrapper>
                    )
                })}
            </div>    

        </Layout>
      </Fragment>
    )
}

export default List

export const pageQuery = graphql`
query ListBySlug($id: String!){
    listsYaml(id: {eq: $id}) {
        name
        name_fr
        slug
        slug_fr
        desc
        desc_fr
        categories
        list {
            ...ListItemFields
        }
        sources {
            link
            link_fr
            title
            title_fr
        }
    }
}
`

/**
 * 
 * CSS
 * 
 */

const listContainer = css`
    margin: 50px auto;
`

const listMeta = css`
    margin-bottom: 40px;

    h1 {
        margin: 0;
    }

    p {
        margin-top: 30px;
    }
`

const listTopBar = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;

    @media screen and (max-width: ${constants.breakpoint1}) {
        > div:last-of-type {
            margin-top: 20px;
        }
    }
`

const itemCategories = css`

    span {
        font-size: 15px;
        text-transform: lowercase;
        padding: 10px 20px;
        margin-right: 10px;
        font-weight: 700;
        border-radius: ${constants.radiusMax};
        background-color: ${constants.white};
    }
`

const listSources = css`
    margin: 10px 0 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    h6 {
        text-transform: uppercase;
        color: ${constants.midgrey};
        font-size: 14px;
        margin: 0 10px 5px 0;
    }

    span {
        margin-right: 10px;
        margin-bottom: 5px;
        padding: 5px 15px;
        border-radius: ${constants.radiusMax};
        border: 1px solid ${constants.midgrey};
        margin-right: 10px;
        font-size: 14px;
        display: inline-block;

        svg {
            margin-bottom: -2px;
            margin-right: 5px;
        }

        a {
            text-decoration: none;
            color: ${constants.darkgrey};
        }
    }
`

const languageSwitch = css`
    a {
        text-decoration: none;
        color: ${constants.midgrey};
        font-size: 14px;
    }

    svg {
        margin-bottom: -2px;
    }
`

const curatorsIntro = css`
    background-color: ${constants.white};
    border-radius: ${constants.radiusBig};
    padding: 20px;
    max-width: 500px;
    
    h6 {
        text-transform: uppercase;
        color: ${constants.midgrey};
        font-size: 14px;
        margin: 0 0 20px 0;
    }
    
    .curator__item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        
        img {
            margin-right: 20px;
            display: block;
            border-radius: ${constants.radiusMax};
            border: 1px solid ${constants.whitegrey};
        }

        h4 {
            margin: 0;
            font-size: 14px;
        }

        p { 
            margin: 0;
            font-size: 14px;
            line-height: 1;
        }

        .curator__meta {
            margin-left: 10px;
        }
    }
`