import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Doc https://github.com/nygardk/react-share#readme
import {
    EmailShareButton, EmailIcon,
    FacebookShareButton, FacebookIcon, 
    LinkedinShareButton, LinkedinIcon,
    TwitterShareButton, TwitterIcon,
    WhatsappShareButton, WhatsappIcon,
    PocketShareButton, PocketIcon,
    RedditShareButton, RedditIcon,
} from "react-share"

import { css } from '@emotion/core'
import { i18nStrings } from '../helpers/translations'

const ListBtns = (props) => {

    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `
    )

    const {
        url,
        lang,
        title,
    } = props

    const btnSize = 36

    return (
        <div css={main}>
            <EmailShareButton 
                url={url} 
                subject={i18nStrings.mailTitle[lang]}
            >
                <EmailIcon size={btnSize} round={true} />
            </EmailShareButton>

            <FacebookShareButton 
                url={url} 
            >
                <FacebookIcon size={btnSize} round={true} />
            </FacebookShareButton>

            <LinkedinShareButton 
                url={url}
                title={title[lang]}
                source={site.siteMetadata.title}
            >
                <LinkedinIcon size={btnSize} round={true} />
            </LinkedinShareButton>

            <TwitterShareButton 
                url={url} 
                title={title[lang]}
                hashtags={[site.siteMetadata.title]}
            >
                <TwitterIcon size={btnSize} round={true} />
            </TwitterShareButton>

            <WhatsappShareButton 
                url={url} 
                title={title[lang]}
            >
                <WhatsappIcon size={btnSize} round={true} />
            </WhatsappShareButton>

            <PocketShareButton 
                url={url} 
            >
                <PocketIcon size={btnSize} round={true} />
            </PocketShareButton>

            <RedditShareButton 
                url={url} 
                title={title[lang]}
            >
                <RedditIcon size={btnSize} round={true} />
            </RedditShareButton>
        </div>
    )
}

export default ListBtns

const main = css`
    display: flex;

    button {
        margin-left: 2px;

        :focus {
            outline: none;
        }
    }
`